.b-faqs {
  background-color: #eef0f5;
}

.b-faqs__container-mobile {
  padding: 10px;
}

.b-faqs__content {
  border-radius: 10px;
  padding: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(191, 198, 207, 0.2);
  box-shadow: 0 5px 15px rgba(191, 198, 207, 0.2);
  margin-bottom: 30px;
}

.b-faqs__header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #4f627a;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: #fff;
  margin-bottom: 8px;
}

.b-faqs__title {
  padding-top: 7px;
  padding-left: 17px;
  padding-right: 17px;
  font: 16px 'poppins', Arial, sans-serif;
  line-height: 48px;
  text-transform: none;
  color: #fff;
}

.b-faqs-arrow {
  width: 18px;
  height: 21px;
  margin-bottom: 3px;
}

.b-faqs__collapse {
  background-color: #607691;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
}

.faqs__collapse .b-faqs__sub-title {
  padding-bottom: 10px;
  font: 18px 'poppinssemibold', Arial, sans-serif;
  letter-spacing: 1px;
  line-height: 29px;
}

.b-faqs__paragraph {
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.b-faqs__link {
  color: #7ea45e;
  transition: color 0.3s;
}

.b-faqs__link:hover {
  color: #7ea45e;
}

.b-faqs__bold {
  font-family: 'poppinssemibold', Arial, sans-serif;
}

.b-faqs__sub-inner-title {
  padding-bottom: 10px;
  font-family: 'poppinssemibold', Arial, sans-serif;
}

.b-faqs__sub-inner-list {
  padding: 0 0 10px 30px;
  list-style: disc;
}
