.change-button-position__container {
    display: flex;
    justify-content: flex-end;
}

.change-button-position {
    left: 20px;
}

@media screen and (max-width: 680px) {

    .b-transfer-success {
        padding: 40px 30px 40px;
        min-width: 100%;
    }

    .b-transfer-success__title {
        padding: 0 0 10px 0;
        font-size: 20px;
        line-height: 20px;
    }

    .b-transfer-success__btn {
        height: 44px;
        right: 0;
        width: 100%;
        font-size: 12px;
        line-height: 44px;
    }

}