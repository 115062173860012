body, .app {
    font: 14px/23px 'poppinsregular', Arial, sans-serif;
    color: #4F627A;
    -webkit-overflow-scrolling : touch !important;
    min-height: 100%;
}

a, span {
    transition: opacity .3s;
}

.freshwidget-theme {
    font-weight: normal;
    font-family: 'poppinsregular', Arial, sans-serif;
    color: #4f627a;
}

.freshwidget-button {
    left: 0 !important;
    right: 10% !important;
}

.fd-btn-bottom a.freshwidget-theme,
.fd-btn-right a.freshwidget-theme {
    box-shadow: 0 5px 15px 0 rgba(191,198,207,0.2);
    font-size: 12px;
    line-height: 24px;
    font-weight: 400!important;
    border-top-right-radius: 0;
}

.fd-btn-bottom a {
right: 0;
}

.app__spinner {
background-color: rgba(0, 0, 0, 0.3);
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 10;
display: flex;
justify-content: center;
align-items: center;
}

.browser-detection__bar {
height: 48px;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
font-weight: bold;
background-color: rgba(0, 0, 0, 0.6);
color: white;
position: fixed;
width: 100%;
z-index: 2;
box-shadow: 0 10px 32px 0 rgba(0, 0, 0, .33);
}
