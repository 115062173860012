.b-field-btn {
    padding: 20px 0;
}

.b-field-btn__label {
    display: block;
    color: #9FA9B7;
    font-size: 12px;
    line-height: 19px;
}

.b-field-btn__input-container {
    position: relative;
    cursor: pointer;
}

.b-field-btn__input {
    height: 59px;
    width: 100%;
    /*min-width: 275px;*/
    padding: 7px 0 20px;
    border: 0;
    border-radius: 0;
    background-color: #fff;
    border-bottom: 1px solid #dfe3e7;
    color: #4F627A;
    font-size: 16px;
    line-height: 26px;
    cursor: pointer;
}

.b-field-btn__btn {
    position: absolute;
    bottom: 16px;
    right: 0;
    height: 34px;
    padding: 0 16px;
    border: none;
    border-radius: 10px;
    background-color: #57E78F;
    color: #4F627A;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .3s;
}

.b-field-btn__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(-90deg);
    height: 10px;
    width: 14px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAOVBMVEUAAAC/xs+/z8+/x8+/xc++xs6+xc6/xc+/xs+/xc+/x8+/xs++xs+/xc+/xs+/xs+/xs+/x8+/xs/6e6HDAAAAEnRSTlMA8BBAMODQz6CAYN/AsJBwUCAnExChAAAAkElEQVQoz3WOSRIDMQgDZWPPvub/j00UygUT4j4BomgAWV8dVgFQrQ85gNSPE4C5H8/4sLESPBDONpCceCdbZiNbHeEY7GDTH5YeKlaa/mrdxW73rkrXrc1Ncc1wFNWbuODBydnEamJ14oed0wVYnDjoS/HiqB+/S4joWVVE9Cl98D95YDpkdCjJiyNSq8DzBvyGEEsQzqbAAAAAAElFTkSuQmCC) center no-repeat;
    background-size: 14px 10px;
    z-index: 1;
}