.b-footer {
    background-color: #fff;
    padding: 68px 0 82px;
}

.m-footer_auth {
    background-color: #4f627a;
}

.b-footer__container {
    justify-content: space-between;
    align-items: center;
}

.b-footer__logo {
    display: block;
    width: 100%;
    height: 32px;
}

.b-footer__nav {
    padding: 10px 0;
    justify-content: flex-end;
    display: flex;
    width: 100%;
}

.b-footer__social-link {
    float: left;
    width: 24px;
    height: 24px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAeFBMVEU6WJg7WZg7WpP9/f48Wpb9/f4AAAA7Wpk8W5bq7fQ8Wpk7WZj///8zUpQ4V5eDlr7u8fdJZaA1VJV4jbjQ1+c9XJxxh7VFYp5mfq+ruNSmtNGNnsT2+Pt+kbtSbKQuTpHh5u+UpMdcdarh5e/T2ujGz+HByt6hsM5rtMrJAAAAC3RSTlP69ib5xv4A57z843BaIN8AAAD1SURBVEjH7dbbjoIwEIDh2i2I206L7Q4HEVjd0/u/4bamxgtN6STeaPzvCPkgw3BRVvJqlV3FS8bfUWaHa84qlIRQMEmMEQW7O9AWTtk8oKHtzDAMxjVtDoCp36hYY5cBjLVSBACuUBSgp1qRAPwpGsBPGrDHOMF+13S+xc8KLj76MIP1yUVg4gsmLUPZoN4+JtC++Qxaq0MJYIePUNxb8X26+k3sAXp13RcmwO4G+LFE0AMRHKhgBOLQXWIGfXS+JrK9caE2tTgNvnk8Lw5C8tF+vhd4RiApIAiBFICC8TVmgng4KblgqxtZU7yFNtvLbSZ4+Q/IZWSu6L8vPgAAAABJRU5ErkJggg==) 0 0 no-repeat;
    background-size: 24px 24px;
    transition: all .3s;
    visibility: hidden;
}

.b-footer__social-link:hover {
    opacity: 0.5;
    visibility: hidden;
}

.b-footer__list {
    float: left;
}

.b-footer__item {
    float: left;
    text-transform: uppercase;
    padding: 0 11px 0 16px;
    letter-spacing: 1px;
}

.b-footer__item a {
    display: block;
    color: #4F627A;
}

.b-footer__item a:hover {
    cursor: pointer;
    opacity: 0.6;
    text-decoration: none;
}

.m-footer_auth .b-footer__item a {
    color: #b7bfc8;
}

/*MEDIA QUERIES*/

@media screen and (device-aspect-ratio: 40/71) {

    .b-footer {
        margin: 0;
        padding: 32px 12px;
    }

    .b-footer__logo {
        margin: auto;
        width: auto;
    }

    .b-footer__nav {
        margin: 24px 0;
    }

    .b-footer__social-link {
        display: none;
    }

    .b-footer__list {
        padding: 0;
        float: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .b-footer_facebook-link {
        margin: auto;
        display: block;
    }
}

@media screen and (min-width: 321px) and (max-width: 480px) {

    .b-footer {
        margin: 0;
        padding: 32px 12px;
    }

    .b-footer__logo {
        margin: auto;
        width: auto;
    }

    .b-footer__nav {
        margin: 24px 0;
    }

    .b-footer__social-link {
        display: none;
    }

    .b-footer__list {
        padding: 0;
        float: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .b-footer_facebook-link {
        margin: auto;
        display: block;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {

    .b-footer {
        margin: 0;
        padding: 32px 12px;
    }

    .b-footer__logo {
        margin: auto;
    }

    .b-footer__nav {
        margin: 24px 0;
        width: 100%;
    }

    .b-footer__social-link {
        display: none;
    }

    .b-footer__list {
        padding: 0;
        float: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .b-footer_facebook-link {
        margin: auto;
        display: block;
    }
}
