@font-face {
    font-family: 'poppinsbold';
    src: url('fonts/popins/poppins-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinslight';
    src: url('fonts/popins/poppins-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsmedium';
    src: url('fonts/popins/poppins-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsregular';
    src: url('fonts/popins/poppins-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinssemibold';
    src: url('fonts/popins/poppins-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}