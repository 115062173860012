.b-voucher {
    margin: 24px 0;
}

.b-voucher__container {
    position: relative;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 15px 0 rgba(191,198,207,0.2);
    cursor: pointer;
    z-index: 1;
}

/*.b-voucher__counter {*/
    /*background: red;*/
    /*color: white;*/
    /*border: 50%;*/
/*}*/

.b-voucher__container.printed {
    background-color: #D2D8DD;
}

.b-voucher__printed-label {
    display: none;
    position: absolute;
    bottom: -17px;
    right: 56px;
    height: 34px;
    width: 269px;
    border-radius: 5px;
    background-color: #9FA9B7;
    color: #fff;
    text-align: center;
    font: 12px 'poppinslight', Arial, sans-serif;
    letter-spacing: 1px;
    line-height: 34px;
    text-transform: uppercase;
}

.b-voucher__container.printed .b-voucher__printed-label {
    display: block;
}

.b-voucher__avatar {
    position: relative;
    width: 80px;
    height: 80px;
}

.b-voucher__avatar-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
}

.b-voucher__avatar-label {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 16px;
    padding: 0 8px;
    border-radius: 5px;
    background-color: #DFE3E7;
    color: #7A8899;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.b-voucher__counter {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #57E78F;
    border-radius: 50%;
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
    line-height: 22px;
}

.b-voucher__counter {
    font-size: 10px;
}

.m-voucher__counter_large {
    width: 30px;
}

.b-voucher__info {
    width: calc(100% - 80px);
    padding: 0 20px 0 15px;
}

.b-voucher__name {
    color: #4f627a;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.02em;
}

.b-voucher__category, .b-voucher__times {
    padding: 3px 0 7px;
    color: #7a8899;
    font: 14px 'poppinslight', Arial, sans-serif;
    letter-spacing: 0.02em;
}

.b-voucher__desc {
    color: #9fa9b7;
    font: 12px 'poppinslight', Arial, sans-serif;
    letter-spacing: 0.03em;
    line-height: 16px;
}

.b-voucher__price {
    position: absolute;
    top: 20px;
    right: -20px;
    height: 34px;
    width: 62px;
    border-radius: 5px;
    background-color: #57E78F;
    color: #4f627a;
    font-size: 12px;
    line-height: 38px;
    text-align: center;
}

.react-spinner {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
}

.b-voucher__checkbox {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 22px;
    height: 21px;
    transition: opacity .3s;
}

.b-voucher__print {
    position: absolute;
    top: 20px;
    right: 50px;
    height: 34px;
    width: 62px;
    border-radius: 5px;
    background-color: #57E78F;
    color: #4f627a;
    font-size: 12px;
    line-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.b-voucher__checkbox-container {
    position: relative;
    width: 22px;
    height: 21px;
}

.b-voucher__checkbox:hover {
    opacity: .4;
}

.b-voucher__checkbox-img {
    width: 22px;
    height: 21px;
    border-radius: 5px;
    background: url('../../../images/print-icon.svg') 0 0 no-repeat;
    background-size: 22px 21px;
    cursor: pointer;
}

.b-voucher__container.printed .b-voucher__checkbox-img {
    background-image: url('../../../images/print-icon-black.svg');
}

.b-voucher__container.printed .b-voucher__checkbox-img--download {
    background-image: url('../../../images/download-icon.svg');
}

.b-voucher__checkbox-label {
    display: none;
    position: absolute;
    top: -15px;
    right: 0;
    font: 9px 'poppinslight', Arial, sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #4F627A;
}

.b-voucher__checkbox-label--print {
    position: absolute;
    top: -20px;
    right: -19px;
    font: 9px 'poppinslight', Arial, sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #4F627A;
}

.b-voucher__container.printed .b-voucher__checkbox-label {
    display: block;
}

/*MEDIA QUERIES*/

@media screen and (max-width: 480px){

    .b-voucher {
        margin: 24px 0;
    }

    .b-voucher__print {
        top: -20px;
        right: -20px;
    }

    .b-voucher__container {
        margin-right: 0;
    }

    .b-voucher__printed-label {
        right: 0;
    }
}