@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./fonts/popins/poppins-regular-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url(./fonts/popins/poppins-medium-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'), url(./fonts/popins/poppins-light-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url(./fonts/popins/poppins-bold-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Semibold';
  src: local('Poppins-Semibold'), url(./fonts/popins/poppins-semibold-webfont.ttf) format('truetype');
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
