.b-voucher-transfer {
  display: table;
  width: 100%;
  height: 100%;
  background-color: #f4f6f8;
}

.b-voucher-transfer__container {
  max-width: 535px;
  margin: 200px auto;
  padding: 60px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(191, 198, 207, 0.2);
}

.b-voucher-transfer__title {
  text-transform: initial;
}

.b-voucher-transfer__btn {
  right: auto;
}

.b-voucher-transfer__checkbox-container {
  position: relative;
  margin: 20px 0;
  padding-left: 46px;
}

.b-voucher-transfer__checkbox {
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAAXNSR0IB2cksfwAAAD9QTFRFAAAA3+Tn3+Ln3+Pm3+Pn3+Pn3+Pm3+Pn3+Lp3+Ln3+Pm3+Pn3+Pn3uPn3+Ln3+Pm3+Tq3+Lm3uLn3+Tm3+Pnz4iqcgAAABV0Uk5TAGCfz/+/r0BQ35B/gOCg0DDv8HDA+0DehAAAALpJREFUeJyFk9sSAiEIQN2idK3Vtfr/b827wsjCm3POMICgVI7tdgcUj6dWI8wOi9hN43aFU7wEDvDO+XkOkAo5rgQb67/iLiZwAlde4ErivFD4yQqVB05oHBih8yJ8vubH8CJoMo+JFyG/3ZKPDMNAvAjhnAzMaxeTQXhrsxuU9zlUY6N8DKoalMP47m4g7qf2q4F4rGlauWxgDgYtbdBKY35Ia2+Ew7HCadl+vMvjOfrxJsWR9feu4j95XxFQFq+KpAAAAABJRU5ErkJggg==)
    0 0 no-repeat;
  background-size: 16px 16px;
  cursor: pointer;
}

.b-voucher-transfer__checkbox.checked {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAAXNSR0IB2cksfwAAAD9QTFRFAAAATWJ6T2J6T2F6T2J6T2F5T2J5UGB4TWN5T2F5TmF6TmJ6TmJ6T2J6TmF5TmJ5UGB6T2F6T2J5TWJ5TmF5n9xMLwAAABV0Uk5TAGCfz/+/r0BQ35B/gOCg0DDv8HDA+0DehAAAALpJREFUeJyFk9sSAiEIQN2idK3Vtfr/b827wsjCm3POMICgVI7tdgcUj6dWI8wOi9hN43aFU7wEDvDO+XkOkAo5rgQb67/iLiZwAlde4ErivFD4yQqVB05oHBih8yJ8vubH8CJoMo+JFyG/3ZKPDMNAvAjhnAzMaxeTQXhrsxuU9zlUY6N8DKoalMP47m4g7qf2q4F4rGlauWxgDgYtbdBKY35Ia2+Ew7HCadl+vMvjOfrxJsWR9feu4j95XxFQFq+KpAAAAABJRU5ErkJggg==)
    0 0 no-repeat;
  background-size: 16px 16px;
}

.b-voucher-transfer__checkbox-description span {
  color: #57e78f;
}

@media screen and (max-width: 768px) {
  .b-voucher-transfer {
    padding: 0 20px;
  }

  .b-voucher-transfer .b-text-field__input {
    width: 100%;
    min-width: 100%;
    box-sizing: border-box;
  }

  .b-voucher-transfer__container {
    max-width: 90%;
    margin: 50px auto;
    margin-top: 200px;
    padding: 20px;
  }

  .b-voucher-transfer__title {
    padding-bottom: 10px;
    font-size: 20px;
    line-height: 20px;
  }

  .b-voucher-transfer__btn {
    height: 44px;
    right: 0;
    width: 100%;
    font-size: 12px;
    line-height: 44px;
  }

  .b-voucher-transfer__checkbox-container {
    padding-left: 30px;
  }

  .b-voucher-transfer__checkbox-description {
    font-size: 12px;
    line-height: 18px;
  }
}
