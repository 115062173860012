.b-home {
}

.b-home__banner {
  height: 600px;
  background: linear-gradient(90deg, #57e78f 0%, rgba(87, 231, 143, 0) 100%),
    url('../../../images/home-banner.jpg') center no-repeat;
  background-size: cover;
}

.b-home__banner-container {
  height: 100%;
  align-items: center;
}

.dr-button {
  background: transparent linear-gradient(180deg, #71fca7 0%, #57e78f 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 33px 49px #0000007d;
  border: 5px solid #70fba6;
  border-radius: 15px;
  opacity: 1;
  padding: 40px;
  margin: 20px;
}

.dr-button:active {
  transform: scale(0.99);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}

.b-home__dr-title {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  max-width: 600px;
  font: 24px 'poppinssemibold', Arial, sans-serif;
}

.b-home__banner-title {
  max-width: 600px;
  font: 48px 'poppinssemibold', Arial, sans-serif;
  line-height: 58px;
  color: #fff;
}

.b-profits {
  padding: 120px 0 115px;
  background-color: #fff;
}

.b-profits__container {
  align-items: center;
  justify-content: space-between;
}

.b-profits__points {
  width: available;
}

.b-profits__list {
}

.b-profits__image {
  text-align: center;
}

.b-profits__item {
  padding: 20px 0;
}

.b-profits__sub-title {
  padding-bottom: 10px;
  font: 18px 'poppinssemibold', Arial, sans-serif;
  letter-spacing: 1px;
  line-height: 29px;
}

.b-downloads__store {
  width: available;
}

.m-downloads__store_ios {
  padding: 76px 124px 76px 20px;
  color: #fff;
  background-color: #4f627a;
}

.m-downloads__store_android {
  padding: 76px 20px 76px 69px;
  background-color: #4de384;
}

.b-downloads__store-holder {
  max-width: 346px;
}

.m-downloads__store-holder_ios {
  float: right;
}

.m-downloads__store-holder_android {
  float: left;
}

.b-downloads__title {
  padding-bottom: 10px;
  font: 24px 'poppinssemibold', Arial, sans-serif;
  letter-spacing: 1px;
  line-height: 28px;
}

.b-downloads__description {
  padding-bottom: 20px;
}

.b-downloads__link {
  display: block;
  height: 50px;
  width: 162px;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}

.b-downloads__link:hover {
  transform: scale(0.96);
  transition: all 0.3s ease-in-out;
}

.m-downloads__link_android {
  width: 169px;
}

/*Media Queries*/

@media screen and (max-width: 480px) {
  .b-home__banner {
    height: 100vh;
  }

  .b-home__banner-container {
    display: flex;
    align-items: flex-end;
  }

  .b-home__banner-title {
    margin: 80px auto 0;
    width: 100%;
    font-size: 4vh;
    font-weight: bold;
    text-align: center;
    line-height: 200%;
  }

  .b-downloads {
    margin-top: 12px;
  }

  .b-downloads__link {
    margin-top: 12px;
  }

  .b-downloads__title {
    margin-top: 12px;
  }

  .b-downloads__store-holder.m-downloads__store-holder_ios {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .b-profits {
    padding: 0;
    margin-top: 12px;
  }

  .b-profits__image {
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .b-profits__points {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .b-downloads__store {
    width: 100%;
  }

  .b-home__banner-title {
    width: auto;
  }

  .b-profits__list {
    width: 100%;
  }

  .b-downloads__store-holder {
    width: 100%;
    max-width: 100%;
  }

  .g-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .iphone-image {
    max-width: fit-content;
  }

  .m-downloads__store_ios,
  .m-downloads__store_android {
    width: 100%;
    padding: 24px;
    min-height: 200px;
    text-align: center;
  }

  .m-downloads__store-holder_ios {
    float: none;
  }

  .m-downloads__link_android {
    width: max-content;
    margin: auto;
  }

  .m-downloads__store-holder_android {
    width: 100%;
    padding: 0;
    float: none;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .b-home {
  }

  .b-home__banner {
    height: 100vh;
  }

  .b-home__banner-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .b-home__banner-title {
    margin: 80px auto 0;
    width: 100%;
    font-size: 5vh;
    font-weight: bold;
    text-align: center;
    line-height: 200%;
    max-width: unset;
  }

  .b-downloads {
    margin-top: 12px;
  }

  .b-downloads__link {
    margin-top: 12px;
  }

  .b-downloads__title {
    margin-top: 12px;
  }

  .b-downloads__store-holder.m-downloads__store-holder_ios {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .b-profits {
    padding: 0;
    margin-top: 12px;
  }

  .b-profits__image {
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .b-profits__points {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .b-downloads__store {
    width: 100%;
  }

  .b-home__banner-title {
    width: auto;
  }

  .b-profits__list {
    width: 100%;
  }

  .b-downloads__store-holder {
    width: 100%;
    max-width: 100%;
  }

  .g-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .m-downloads__store_ios,
  .m-downloads__store_android {
    width: 100%;
    padding: 24px;
    min-height: 200px;
    text-align: center;
  }

  .m-downloads__store-holder_ios {
    float: none;
  }

  .m-downloads__link_android {
    width: max-content;
    margin: auto;
  }

  .m-downloads__store-holder_android {
    width: 100%;
    padding: 0;
    float: none;
  }
}
