.b-privacy-policy {
  background-color: #f6f7f9;
}

.b-privacy-policy-mobile__container {
  padding: 20px 20px;
}

.b-privacy-policy__content {
  padding: 20px;
  background-color: #f6f7f9;
  -webkit-box-shadow: 0 5px 15px rgba(255, 255, 255, 0);
  box-shadow: 0 5px 15px rgba(191, 198, 207, 0);
}

.b-privacy-policy-mobile__container h1,
.b-privacy-policy__title {
  padding-bottom: 10px;
  font: 20px 'poppinssemibold', Arial, sans-serif;
  line-height: 48px;
  text-transform: uppercase;
}

.b-privacy-policy-mobile__container h2,
.b-privacy-policy__sub-title {
  padding-bottom: 10px;
  font: 18px 'poppinssemibold', Arial, sans-serif;
  letter-spacing: 1px;
  line-height: 29px;
  word-wrap: break-word;
}

.b-privacy-policy-mobile__containerp,
.b-privacy-policy__paragraph {
  padding-bottom: 10px;
}

.b-privacy-policy-mobile__container a,
.b-privacy-policy__link {
  color: #7ea45e;
  transition: color 0.3s;
  word-wrap: break-word;
}

.b-privacy-policy__link:hover {
  color: #7ea45e;
}

.b-privacy-policy__bold {
  font-family: 'poppinssemibold', Arial, sans-serif;
}

.b-privacy-policy-mobile__container h3,
.b-privacy-policy__sub-inner-title {
  padding-bottom: 10px;
  font-family: 'poppinssemibold', Arial, sans-serif;
}

.b-privacy-policy-mobile__container ul,
.b-privacy-policy__sub-inner-list {
  padding: 0 0 10px 30px;
  list-style: disc;
}
