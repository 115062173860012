.b-custom-select svg {
    opacity: 0;
}

.b-custom-select hr {
    display: none;
}

.b-custom-select {
    padding: 20px 0;
}

.b-custom-select__label {
    display: block;
    color: #9FA9B7;
    font-size: 12px;
    line-height: 19px;
}

.b-custom-select__container {
    position: relative;
    border-bottom: 1px solid #dfe3e7;
    height: 58px;
    width: 100%;
    min-width: auto;
    padding: 0 0 20px;
    border-radius: 0;
    color: #4F627A;
    font-size: 16px;
    line-height: 15px;
}

.b-custom-select__list {
    position: relative;
    height: 58px;
    width: 100%;
    min-width: 275px;
    padding: 7px 0 20px;
    border: 0;
    border-radius: 0;
    color: #4F627A;
    font-size: 16px;
    line-height: 15px;
}

.b-custom-select__link {
    display: inline-block;
    width: 100%;
    color: #4f627a;
}