.b-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

.m-header_auth {
    background-color: #4F627A;
}

.b-header__container {
    justify-content: space-between;
    align-items: center;
    height: 128px;
}

.m-header__container_auth {
    height: 68px;
}

.b-header__logo {
    display: block;
    width: 100%;
    height: 48px;
}

.m-header__logo_auth {
    width: auto;
    height: 32px;
}

.b-header__nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.b-header__list {

}

.b-header__item {
    float: left;
    padding: 20px 22px;
    text-transform: uppercase;
    line-height: 31px;
    height: 80px;
}

.b-header__item:last-child {
    padding: 20px 0 20px 20px;
}

.m-header__item_auth {
    height: 68px;
    line-height: 30px;
}

.b-header__item a, .b-header__item span {
    cursor: pointer;
    color: #3e4f67;
    letter-spacing: 0.14em;
    font-family: 'poppinssemibold', Arial, sans-serif;
}

.b-header__item a:hover, .b-header__item span:hover {
    opacity: 0.5;
}

.m-header__item_auth a, .m-header__item_auth span {
    color: #fff;
}

.m-header__item_auth a.active {
    color: #57E78F;
    text-decoration: none;
}

.m-header__item_auth a.active:hover {
    opacity: 1;
}

.m-header__item_language {
    position: relative;
}

.b-language {
    position: relative;
    transition: opacity .3s;
}

.b-language:hover {
    opacity: 0.5;
}

.b-language__label {
    display: block;
    padding-right: 14px;
}

.b-language__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(-180deg);
    width: 9px;
    height: 6px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAMBAMAAACdPPCPAAAAGFBMVEUAAABPYXpPYXpOYXlNYnpQYHpQYIBPYnpwouXCAAAAB3RSTlMA78+gYDAQHn7ItwAAAEpJREFUCNdjAAEXBihgFQyAspLK1aAs8/JiCIOlvLzcAcwKBLJEQQw2dSCrKAHIYi4HAQMgyx3MKgEaJg5mFQYADYMANaBhEFAMAO92GRvw+fR9AAAAAElFTkSuQmCC) 0 0 no-repeat;
    background-size: 9px 6px;
}

.b-language__drop-down {

}

.b-language__drop-down {
    display: none;
    position: absolute;
    top: 0;
    left: -10px;
    width: 160px;
    padding: 15px 30px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(191, 198, 207, 0.2);
    color: #9FA9B7;
}

.b-language__drop-down.opened {
    display: block;
}

.b-language__item {
    line-height: 23px;
    padding: 5px 0;
    text-transform: initial;
    cursor: pointer;
}

.b-language__item a {
    font-family: 'poppinsregular', Arial, sans-serif;
    color: #9FA9B7;
}

.b-language__item span {
    cursor: default;
}

.b-language__item span:hover {
    opacity: 1;
}

/*Media Queries*/

@media screen and (device-aspect-ratio: 40/71) {

    .b-header__logo {
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        cursor: pointer;
        width: auto;
    }

    .b-header__list > li {
        padding: 0 7px;
        cursor: pointer;
        font-size: 10px;
    }

    .b-language__drop-down {
        top: 30px;
        left: 0;
        padding: 12px;
    }

    .b-language__drop-down.opened > ul {
        font-size: 20px;
        list-style-type: none;
        text-align: center;
    }

    .b-header__item {
        padding: 0;
    }

    .b-header__item:last-child {
        padding: 0;
    }

    .b-header__nav {
        margin-top: 40px;
        justify-content: center;
    }

    .m-header__container_auth {
        height: auto;
        display: flex;
        align-content: center;
        justify-content: center;
    }

    .m-header__logo_auth {
        margin-top:12px;
    }
}

@media screen and (min-width: 321px) and (max-width: 480px) {

    .b-header__logo {
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        cursor: pointer;
        width: auto;
    }

    .b-header__list > li {
        padding: 0 9px;
        cursor: pointer;
        font-size: 12px;
    }

    .b-language__drop-down {
        top: 30px;
        left: 0;
        padding: 12px;
    }

    .b-language__drop-down.opened > ul {
        font-size: 20px;
        list-style-type: none;
        text-align: center;
    }

    .b-header__item {
        padding: 0;
    }

    .b-header__item:last-child {
        padding: 0;
    }

    .b-header__nav {
        margin-top: 40px;
        justify-content: center;
    }

    .m-header__container_auth {
        height: auto;
        display: flex;
        align-content: center;
        justify-content: center;
    }

    .m-header__logo_auth {
        margin-top:12px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px){

    .b-header__logo {
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        cursor: pointer;
        width: auto;
    }

    .b-header__list > li {
        padding: 0 16px;
        cursor: pointer;
        font-size: 13px;
    }

    .b-language__drop-down {
        top: 30px;
        left: 0;
        padding: 12px;
    }

    .b-language__drop-down.opened > ul {
        font-size: 20px;
        list-style-type: none;
        text-align: center;
    }

    .b-header__item {
        padding: 0;
    }

    .b-header__item:last-child {
        padding: 0;
    }

    .b-header__nav {
        margin-top: 40px;
        justify-content: center;
    }

    .m-header__container_auth {
        height: auto;
        display: flex;
        align-content: center;
        justify-content: center;
    }

    .m-header__logo_auth {
        margin-top:12px;
    }
}
