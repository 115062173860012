.b-profile__container {
  max-width: 980px;
  margin: 0 auto;
  padding: 112px 20px 40px;
}

.b-profile__form {
  justify-content: space-between;
  padding: 36px 40px 20px 50px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(191, 198, 207, 0.2);
}

.b-profile__column {
  width: 50%;
}

.m-profile__column_left {
  padding-right: 10px;
}

.m-profile__column_right {
  padding-left: 10px;
}

.b-profile__control {
  padding: 40px 0;
}

.b-profile__btn {
  right: 0;
  float: right;
}

.checkbox_profile {
  margin: 10px;
}

/*MEDIA QUERIES*/

@media screen and (max-width: 768px) {
  .b-profile__container {
    margin-top: 80px;
  }

  .b-profile__form {
    padding: 12px;
  }
}
