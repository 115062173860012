.b-contact__container {
    margin-top: 190px;
    padding: 0 20px;
}

.b-contact__title {
    padding-bottom: 10px;
    font: 38px 'poppinssemibold', Arial, sans-serif;
    line-height: 48px;
    text-transform: uppercase;
}

.b-contact__sub-title {
    padding-bottom: 10px;
    font: 18px 'poppinssemibold', Arial, sans-serif;
    letter-spacing: 1px;
    line-height: 29px;
}

.b-contact__paragraph {
    padding-bottom: 10px;
}

.b-contact__icons {
    font-size: 55px;
    /*padding: 0 20px 0 0;*/
}

.b-contact__href {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 480px) {

    .b-contact__container {
        text-align: center;
    }

    .b-contact__paragraph {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
    }

    .b-contact__href {
        display: flex;
        align-items: center;
    }
}