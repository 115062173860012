.notFoundPage {
    background: url("../../../images/golf_404.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'poppinssemibold', Arial, sans-serif;
}

.notFoundPage__header {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 24px;
    color: #4F627A;
    text-transform: uppercase;
    text-align: center;
}

.notFoundPage__header--notification {
    font-family: 'poppinsregular', Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    color: #57E78F;
}

.notFoundPage__buttons {
    text-align: center;
    margin-top: 12px;
    padding: 12px;
}

.notFoundPage__buttons--cta {
    height: 56px;
    margin: 12px;
    padding: 0 20px;
    border-radius: 10px;
    background-color: #57E78F;
    color: #4F627A;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 46px;
    text-align: center;
    border: 0;
    text-transform: uppercase;
    cursor: pointer;
    min-width: 200px;
}

.notFoundPage__buttons--cta:hover {
    transform: scale(.96);
    transition: all .3s ease-in-out;
}

.notFoundPage__logo {
    display: block;
    position: fixed;
    top:0;
    margin: 40px 0 0 40px;
    width: 200px;
    height: auto;
    cursor: pointer;
}

@media screen and (device-aspect-ratio: 40/71) {
    .notFoundPage {
        background: url("../../../images/404_PAGE@0,5x.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        justify-content: flex-start;
        padding-top: 48px;
    }

    .notFoundPage__header {
        font-size: 28px;
        margin-bottom: 16px;
    }

    .notFoundPage__header--notification {
        color: #ffffff;
        padding: 0 12px;
        line-height: 24px;
    }

    .notFoundPage__buttons {
        margin-top: 70px;
    }

    .notFoundPage__logo {
        display: none;
    }
}

@media screen and (min-width: 321px) and (max-width: 480px) {
    .notFoundPage {
        background: url("../../../images/404_PAGE@0,5x.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        justify-content: flex-start;
        padding-top: 48px;
    }

    .notFoundPage__header {
        font-size: 34px;
        margin-bottom: 16px;
    }

    .notFoundPage__header--notification {
        color: #ffffff;
        padding: 0 12px;
        line-height: 24px;
        font-size: 19px;
    }

    .notFoundPage__buttons {
        margin-top: 132px;
        flex: none;
    }

    .notFoundPage__logo {
        display: none;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) and (orientation: landscape) {
    .notFoundPage__header {
        font-size: 28px;
        margin-bottom: 16px;
    }

    .notFoundPage__header--notification {
        color: #ffffff;
        padding: 0 12px;
        line-height: 24px;
        font-size: 14px;
    }

    .notFoundPage__buttons {
        margin-top: 0;
    }

    .notFoundPage__buttons--cta {
        height: 46px;
        margin: 8px;
    }

    .notFoundPage__logo {
        display: none;
    }
}