/*html,
body,
#root,
.app,
.g-page,
.g-auth-content {
    display: table;
    height: calc(100% - 243px);
    width: 100%;
}*/

html,
body,
#root,
.app,
.g-page {
  height: 100%;
  min-height: 100%;
}

.g-auth-content {
  min-height: calc(100% - 194px);
}

.g-container {
  /*!*max-width: 980px;*! RWD*/
  padding: 0 20px;
  margin: 0 auto;
}

.g-clear-fix:before,
.g-clear-fix:after {
  content: "";
  display: table;
  clear: both;
}

.g-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.g-uppercase {
  text-transform: uppercase;
}

.g-auth-content {
  background-color: #f5f7f9;
}

.g-submit-btn {
  position: relative;
  right: -80px;
  height: 56px;
  padding: 0 20px;
  border-radius: 10px;
  background-color: #57e78f;
  color: #4f627a;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 56px;
  text-align: center;
  border: 0;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
}

.g-submit-btn:hover {
  background-color: #58f59c;
}

.g-submit-btn:active {
  background-color: #59d77d;
}

.m-form__btn_grey {
  width: 335px;
  height: auto;
  padding: 0;
  background-color: #dfe3e7;
  color: #7a8899;
  line-height: 44px;
  letter-spacing: 1px;
}

.m-form__btn_large {
  width: 430px;
}

.m-form__btn_size {
  font-size: 15px;
}

.m-form__btn_grey:hover {
  background-color: #e9edf1;
}

.m-form__btn_grey:active {
  background-color: #d0d3d7;
}

.g-form__title {
  padding-bottom: 20px;
  font: 26px/28px "poppinssemibold", Arial, sans-serif;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #4f627a;
}

.m-form__title_small {
  font-size: 24px;
}

.m-form__title_transfer {
  padding: 0 60px 20px;
}

.g-form__img {
  display: block;
  margin: 20px 0 40px;
}

.g-form__paragraph {
  padding-bottom: 20px;
}

.g-form__paragraph span {
  color: #4de384;
}

.g-form__container {
  justify-content: space-between;
}

.g-form__fields-column {
  width: 50%;
}

.m-form__fields-column_left {
  padding-right: 30px;
}

.m-form__fields-column_right {
  padding-left: 30px;
}

.g-form__options {
  padding-top: 20px;
  justify-content: space-between;
  align-items: center;
}

.m-form__options_grey {
  padding-top: 0;
}

.g-form__options-text-container {
  flex-direction: row-reverse;
  align-items: center;
  height: 59px;
}

.m-form__options-text-container_registration {
  position: relative;
  left: 20px;
  bottom: -20px;
}

.g-form__options-text {
  font-size: 12px;
  color: #9fa9b7;
}

.m-form__options-text_registration {
  max-width: 268px;
  text-align: right;
  line-height: 19px;
}

.g-form__options-text a {
  font-family: "poppinssemibold", Arial, sans-serif;
  color: #9fa9b7;
}

.g-form__link {
  color: #9fa9b7;
  cursor: pointer;
  transition: color 0.3s;
}

.g-form__link:hover {
  color: #b7c2d1;
}

.g-form__link:active {
  color: #8c95a1;
}

.m-form__btn-holder_confirm {
  padding-top: 20px;
}

.m-form__btn-holder_right {
  float: right;
}

.m-form__btn_confirm {
  float: right;
}

.m-form__btn_login {
  right: -20px;
  padding: 0 51px;
}

.m-form__btn_home {
  right: auto;
  left: -20px;
}

/*popup*/

.g-popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.g-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #4f627a;
  opacity: 0.8;
  z-index: 9;
  height: 100%;
  overflow-y: auto;
}

.g-popup-wrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 10;
}

.g-popup-wrap__container {
  height: 100%;
  margin: 0 auto;
  border-collapse: collapse;
}

.g-popup-wrap__container-inner {
  padding: 20px;
  margin: 0;
  border: 0;
  vertical-align: middle;
}

.g-popup {
  width: 100%;
  max-width: 395px;
  position: relative;
  padding: 60px 60px 40px;
  box-shadow: 0 5px 15px rgba(191, 198, 207, 0.2);
  border-radius: 10px;
  background-color: #ffffff;
  z-index: 10;
}

.m-popup_wide {
  min-width: 415px;
  max-width: 415px;
}

.m-popup_more_wide {
  min-width: 434px;
  max-width: 434px;
  padding: 60px 0 40px;
}

.m-popup_large {
  max-width: 730px;
}

.g-popup__container {
  position: relative;
}

.g-popup__close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  padding: 5px;
  box-sizing: content-box;
  cursor: pointer;
}

.g-popup__close-btn:before,
.g-popup__close-btn:after {
  content: "";
  position: absolute;
  top: 2px;
  width: 2px;
  height: 20px;
  background-color: #bfc6cf;
  transition: background-color 0.3s;
}

.g-popup__close-btn:before {
  left: 10px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.g-popup__close-btn:after {
  right: 13px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}

.g-popup__close-btn:hover:before,
.g-popup__close-btn:hover:after {
  background-color: #e0e8f2;
}

/*popup*/

.sk-ball-scale-multiple {
  width: 36px;
  height: 36px;
}

/*MEDIA QUERIES*/

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices for removing selector jumping connected with overflow*/
  .g-popup-wrap {
    position: absolute;
  }
}

@media screen and (device-aspect-ratio: 40/71) {
  .g-popup {
    width: auto;
    padding: 20px 12px;
    /*max-width: 290px;*/
  }

  .g-submit-btn {
    right: auto;
    padding: 0 20px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .m-form__btn_large {
    width: auto;
    max-width: 235px;
    height: auto;
    line-height: 27px;
    font-size: 14px;
    padding-top: 4px;
  }

  .g-popup-wrap__container-inner {
    padding: 20px 0;
  }

  .m-form__options-text_registration {
    max-width: 100%;
    text-align: left;
  }

  .m-form__btn_grey {
    width: auto;
  }

  .m-form__options-text-container_registration {
    display: flex;
    justify-content: center;
    left: 0;
    bottom: 0;
    padding-top: 16px;
  }
}

@media screen and (min-width: 321px) and (max-width: 480px) {
  .g-popup {
    width: auto;
    padding: 16px;
  }

  .g-submit-btn {
    right: auto;
    padding: 0 20px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .m-form__btn_large {
    width: auto;
    height: auto;
    line-height: 27px;
    max-width: 275px;
    padding-top: 4px;
  }

  .m-form__options-text_registration {
    max-width: 100%;
    text-align: left;
  }

  .m-form__btn_grey {
    width: auto;
  }

  .m-form__options-text-container_registration {
    display: flex;
    justify-content: center;
    left: 0;
    bottom: 0;
    padding-top: 16px;
  }
}
