.log-out-warn {
    min-width: 350px;
    padding: 60px 0 52px;
}

.b-favorites-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 22px;
    height: 21px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAqCAMAAAD/A0kuAAAAdVBMVEUAAAC3v8u3vcm3vsm3vcm2vsm3vsm6wcq3vsm3vsm2vsm3vsm3vsm4vcm5v8q3v8q3vsm2vsm3vcm3v8m4v8u+wM23vsm3vcq3vsm3vsm3vsm2vcm3vsm2vsm2vsm4vsm2vcm3vsi3vsm5v8vGxsbV1dW3vsl6gnT6AAAAJnRSTlMAQJ/Y9MDfG4Rn676JRjAg8NuAUisQ0GDGuJiPdXGkKK+xqRYJBl6mNF8AAAGCSURBVDjLjZXptoIgFIUPgpoTmpo5pY28/yPeyxQp1GL/qbXX1xccRcGVgJARPHNg/6n92BJzGJdecM5EDr5ioY79Vuytjrk4DIXaT4yQUPuJAaTaSwwg1LWf2Ef9mpRYqQ8vJ/aMKZrzvpJira7CfEY0fhquRUl+C3HKZCoqa1qpIsXDLU9QCwBrF7FNGi05b/uoW6HbVZzV9E7TwycYTgg2QVP4+QPA4iOoG8XZQU0dCAhDQwQMPyNg0gAkNu1mEwBDe7CG9mB5muN3+iTYY2GaQtBXF3zV7J6+2OxFXADDGrqy4Yp7EZiYXVot4ns72xvp5TrsVfQnG+Z31NkTbqNvy4hWcNXMcpyYSwEzd7inMVvtaE4fnbJsouYkjhac6ecEyphIJv695t/27EMNo7yyd4JSHsTu4RxGGUjM4GIcrWsYGsVBgDXuGkdtdDiPAeJc445xLEwlHUvZrGOqu2UHDwpdWtO1i8IH11uH3Om2pXfifKoPJM0KsFJkKXmL/wDcOUtGEl05QAAAAABJRU5ErkJggg==) 0 0 no-repeat;
    background-size: 22px 21px;
    cursor: pointer;
}

.b-favorites-icon.favourite {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAqCAMAAAD/A0kuAAAAOVBMVEUAAABX549W545V6o9Q749X549W549X545X5o5V549W6Y9W5o9W545W545X5o5W5o9Y549X6I5X54/TCoQMAAAAEnRSTlMAQPAwEL+A4NBgUM/AoLCPIG+UdpP2AAAA5klEQVQ4y5XVW67CMAxF0cRJk+bRB57/YO8FVT2CxNicT7S0hSxo3WyFqDjjPP/PG/HyxIs9jLQeRloPI20KI62HkdbDSOthpPWwOV0hlXTwB71jOnz4VKd/HEtmYXlJD3++YNwam9a26HY2b3P8w1y32+4CWS0FB22w0LqFVu21c9XsGt29uBottG6hdQtNkqXbYpv8kxi3/4KbhNsEs7jRBhkH+a86rg44yTjpx8B2/RhY+3qMXmv/dg7PoP71QZfPUfharvd9Ml8r82Pk8la4eJp9DSrjG3H6fEyUU3TDYsp0h/8AG9FCd9HYaboAAAAASUVORK5CYII=);
}

.log-out-warn__header {
    position: relative;
    font-size: 16px;
}

.log-out-warn__count {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 82px;
    height: 44px;
    border-radius: 0 10px 10px 0;
    background-color: #dfe3e7;
    color: #9fa9b7;
    line-height: 44px;
    text-align: center;
}

.log-out-warn__price {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 82px;
    height: 44px;
    border-radius: 10px 0 0 10px;
    background-color: #57e78f;
    color: #4f627a;
    line-height: 44px;
    text-align: center;
}

.log-out-warn__avatar {
    width: 160px;
    height: 160px;
    border: 2px solid #dfe3e7;
    border-radius: 50%;
    margin: 0 auto;
    background-size: cover;
}

.log-out-warn__content {
    color: #4f627a;
    font: 16px 'poppinslight', Arial, sans-serif;
    letter-spacing: 0.03em;
    text-align: center;
}

.log-out-warn-button__container {
    position: absolute;
    width: 150px;
    height: 50px;
    background-color: #57e78f;
    border-radius: 10px;
    bottom: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4f627a;
    cursor: pointer;
    transition: all .3s;
}

.log-out-warn-button__container:hover {
    background-color: #58f59c;
}

.log-out-warn-button__container:active {
    background-color: #59d77d;
}

.accept {
    background-color: #ffca52;
    left: -50px;
}

.accept:hover {
    background-color: #ffd34e;
}

.accept:active {
    background-color: #e0b148;
}

.dismiss {
    right: -50px;
}

.log-out-warn__desc {
    margin-bottom: 37px;
}

.log-out-warn__desc-name {
    padding-bottom: 7px;
    color: #4f627a;
    font-size: 16px;
    letter-spacing: 0.05em;
}

.log-out-warn__desc-ticket {
    padding-bottom: 10px;
    font-size: 14px;
    color: #7a8899;
}

.log-out-warn__desc-text {
    padding-bottom: 6px;
}

.log-out-warn__criteria {

}

.log-out-warn__criteria-title {
    padding-bottom: 11px;
    font-size: 14px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
}

.log-out-warn__criteria-text {
    padding-bottom: 5px;
}

/*MEDIA QUERIES*/

@media screen and (max-width: 480px){

    .accept {
        left:0;
    }

    .dismiss {
        right: 0;
    }
}
