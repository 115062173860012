.b-vouchers {
    padding: 102px 0 80px;
}

.b-vouchers__container {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 20px;
}

.b-vouchers__options {
    justify-content: space-between;
}

.b-vouchers__filters {
    align-items: center;
    justify-content: flex-end;
}

.b-vouchers__filter-count {
    align-items: center;
}

.b-vouchers__filter-count-btn {
    width: 120px;
    height: 32px;
    border-radius: 5px;
    background-color: transparent;
    font-family: 'poppinslight', Arial, sans-serif;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    padding: 0 10px;
    margin: 0 5px;
    border: none;
    color: #9fa9b7;
}

.b-vouchers__filter-count-checkbox {
    background-color: transparent;
    font-family: 'poppinslight', Arial, sans-serif;
    text-align: right;
    line-height: 32px;
    color: #9fa9b7;
    cursor: pointer;
    margin-right: 20px;
}

.b-vouchers__filter-count-btn.active {
    background-color: #dfe3e7;
    color: #4f627a;
    cursor: pointer;
    font-weight: bold;
    border: none;
    box-shadow: 0 5px 15px 0 rgba(191,198,207,0.2);
}

.b-vouchers__list {
    flex-wrap: wrap;
    margin-bottom: 29px;
}

.b-vouchers__navigation {
    justify-content: flex-end;
}

.b-print {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAoBAMAAAB3OwUkAAAAMFBMVEUAAABY549Q749W549V6o9W545W549W5o9X549X549V545Y549X549X549V549X54/KSqLAAAAAD3RSTlMAQBDwMMCA0N9vPyCfv1/vpQDwAAAAqElEQVQoz2MAApb+/zBQx4AAzP8RoAUhLI8k/NkBq/B/EyRhGIsVrBxTGKYcU/jzBqzC/w9gFzbAEGa0Bwp/xBBm2JeWhiKMAHQQZlo1H1X45yoFoOj5//9RhIHcPwoM6v8xhf8XMeRjE/7G0P8fAwSp/gBKYgAFpv9YhbmxCl/aCxbGBAMjjO55coQdBQXRo0FQUIScKEYGOIUxsxRyimWpRxb9DsolAOU0ttIn4GR4AAAAAElFTkSuQmCC) left center no-repeat;
    background-size: 22px 20px;
    cursor: pointer;
}

.b-print__label {
    display: block;
    height: 32px;
    padding-left: 42px;
    font-family: 'poppinslight', Arial, sans-serif;
    line-height: 36px;
    color: #4f627a;
}

.b-search {
    position: relative;
}

.b-search__icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 22px;
    height: 22px;
    transform: translateY(-50%);
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAsCAMAAADGmMENAAAANlBMVEUAAAC/x8+/xs+/z8+/xs+/xs+/xs+/xc+/xc++xs+/xs+/xs+/xs++xc6/xc+/x8+/x8+/xs/5mjwcAAAAEXRSTlMAYPAQT+CQMM/AsICg0J8gX1rIA9sAAAD/SURBVDjL7ZRbEoIwDEX7SAJFWsz+N6uhM6KkoTL46f0jPXPJo6mzBCWNzGMq4HpaSuCXQomH8K2yG3+z2Tiy0mjZD9UYCYbnBxBW+6HtHFbWbxGPKx7NNKb4EZvWZFoFysG8j84S1aUuQZy1ydTMRUwwGtWoX0rQu4a8mO/HLdauKWkM6ESoTZM+Su8G+rfpMyTNHowR65bzUxrczq7QeJxJvlIldTp4ZTrx1OQdnbpVC/durN6Gso+Wug1a2dq03K5GhPctdA91i03YfiE0nFkpm85eSlUvmwVLIym8sXM8gkURKCFzTiTjtuG9/vBvYTgDu9CB7Se7L0CEb9kHo1UarvwJcAcAAAAASUVORK5CYII=) 0 0 no-repeat;
    background-size: 22px 22px;
}

.b-search__input {
    height: 43px;
    width: 275px;
    margin-left: 42px;
    padding: 10px 0;
    border: none;
    line-height: 43px;
    border-bottom: 1px solid #dfe3e7;
    background-color: #f5f7f9;
    font: 14px 'poppinsregular', Arial, sans-serif;
    color: #4F627A;
}

.b-search__input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #9FA9B7;
}
.b-search__input::-moz-placeholder { /* Firefox 19+ */
    color: #9FA9B7;
}
.b-search__input:-ms-input-placeholder { /* IE 10+ */
    color: #9FA9B7;
}

.searchActive {
    padding-right: 10px;
}
.searchPrinted {
    padding-left: 10px;
}

/*MEDIA QUERIES*/

@media screen and (device-aspect-ratio: 40/71) {

    .b-vouchers__options {
        margin-top: 80px;
        align-items: center;
        justify-content: center;
    }

    .b-vouchers__filters {
        margin-top: 24px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .b-search__input {
        width: 100%;
    }

    .b-vouchers__filter-count {
        flex-direction: column;
    }

    .b-sort {
        flex-wrap: wrap;
    }
}


@media screen and (min-width: 321px) and (max-width: 480px) {

    .b-vouchers__options {
        margin-top: 80px;
        align-items: center;
        justify-content: center;
    }

    .b-vouchers__filters {
        margin-top: 24px;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .b-search__input {
        width: 100%;
    }

    .b-vouchers__filter-count {
        justify-content: center;
    }

    .b-sort {
        flex-wrap: nowrap;
    }

    .b-vouchers__filter-count-checkbox {
        margin-top: 16px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {

    .b-search__input {
        width: 100%;
    }

    .b-vouchers__options {
        margin-top: 80px;
        align-items: center;
        justify-content: center;
    }

    .b-vouchers__filters {
        margin-top: 24px;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .b-vouchers__filter-count {
        justify-content: center;
    }

    .b-sort {
        flex-wrap: nowrap;
    }

    .b-vouchers__filter-count-checkbox {
        margin-top: 16px;
    }
}