.b-password-change {
    display: table;
    width: 100%;
    height: 100%;
    background-color: #F4F6F8;
}

.b-password-change__container {
    max-width: 395px;
    margin: 200px auto;
    padding: 40px 50px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 15px 0 rgba(191,198,207,0.2);
}

@media screen and (max-width: 680px) {

    .b-password-change__container {
        margin: 50px auto;
        padding: 20px;
    }

    .b-password-change .g-popup {
        padding: 40px 30px 40px;
    }

    .b-password-change .g-submit-btn {
        float: none;
        width: 100%;
        height: 44px;
        right: 0;
        font-size: 12px;
        line-height: 44px;
    }

    .b-password-change__form {

    }

    .b-password-change__title {
        padding-bottom: 10px;
        font-size: 20px;
        line-height: 20px;
    }

    .b-password-change__options {

    }

    .b-password-change__btn-holder {
        float: none;
        width: 100%;
    }

    .b-password-change__btn {
        height: 44px;
        right: 0;
        width: 100%;
        font-size: 12px;
        line-height: 44px;
    }

    .b-text-field__error {
        font-size: 12px;
    }
}
