.remove-capitalize {
    text-transform: none;
}
.printNotification {
    position: relative;
    height: 56px;
    padding: 0 40px;
    border-radius: 10px;
    color: #4F627A;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 56px;
    text-align: center;
    border: 0;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
}

.cancel-btn {
    background-color: #cd5c5c;
}

.accept-btn {
    background-color: #57E78F;
}

.notification-text {
    font: 22px/28px 'poppinssemibold', Arial, sans-serif;
}

.cancel-btn:hover {
    background-color: #de6e6e;
}
.accept-btn:hover {
    background-color: #68F890;
}

.btn_container {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    position: relative;
}
@media screen and (max-width: 680px) {

    .b-transfer-success {
        padding: 40px 30px 40px;
        min-width: 100%;
    }

    .b-transfer-success__title {
        padding: 0 0 10px 0;
        font-size: 20px;
        line-height: 20px;
    }

    .b-transfer-success__btn {
        height: 44px;
        right: 0;
        width: 100%;
        font-size: 12px;
        line-height: 44px;
    }
}