.b-terms {
  background-color: #f6f7f9;
}

.b-terms-mobile__container {
  padding: 20px 20px;
}

.b-terms__content {
  padding: 20px;
  background-color: #f6f7f9;
  -webkit-box-shadow: 0 5px 15px rgba(191, 198, 207, 0);
  box-shadow: 0 5px 15px rgba(191, 198, 207, 0);
}

.b-terms__title {
  padding-bottom: 10px;
  font: 20px 'poppinssemibold', Arial, sans-serif;
  line-height: 48px;
  text-transform: uppercase;
}

.b-terms__sub-title {
  padding-bottom: 10px;
  font: 18px 'poppinssemibold', Arial, sans-serif;
  letter-spacing: 1px;
  line-height: 29px;
}

.b-terms__paragraph {
  padding-bottom: 10px;
}

.b-terms__link {
  color: #7ea45e;
  transition: color 0.3s;
}

.b-terms__link:hover {
  color: #7ea45e;
}

.b-terms__bold {
  font-family: 'poppinssemibold', Arial, sans-serif;
}

.b-terms__sub-inner-title {
  padding-bottom: 10px;
  font-family: 'poppinssemibold', Arial, sans-serif;
}

.b-terms__sub-inner-list {
  padding: 0 0 10px 30px;
  list-style: disc;
}

@media screen and (max-width: 480px) {
  .b-privacy-policy__title {
    font: 20px 'poppinssemibold', Arial, sans-serif;
  }
}
