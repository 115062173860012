.b-text-field {
    padding: 20px 0;
}

.b-text-field__label {
    display: block;
    color: #9FA9B7;
    font-size: 12px;
    line-height: 19px;
}

.b-text-field__label.disabled {
    color: #cfd4db;
}

.b-text-field__input-container {
    position: relative;
}

.b-text-field__input {
    height: 59px;
    width: 100%;
    /*min-width: 275px;*/
    padding: 7px 0 20px;
    border: 0;
    border-radius: 0;
    background-color: #fff;
    border-bottom: 1px solid #dfe3e7;
    color: #4F627A;
    font-size: 16px;
    line-height: 26px;
}

.b-text-field__input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #9FA9B7;
}
.b-text-field__input::-moz-placeholder { /* Firefox 19+ */
    color: #9FA9B7;
}
.b-text-field__input:-ms-input-placeholder { /* IE 10+ */
    color: #9FA9B7;
}

.b-text-field__input.disabled {
    color: #c5cbd2;
    border-bottom-color: #eceff1;
}

.b-text-field__input.error {
    border-bottom-color: #b7170b;
}

.b-text-field__error {
    position: absolute;
    top: 60px;
    left: 0;
    color: #b7170b;
    line-height: 16px;
}
