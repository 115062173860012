.b-sort {
    position: relative;
    color: #9fa9b7;
    font-family: 'poppinslight', Arial, sans-serif;
    margin-right: 10px;
}

.b-sort__trigger {
    position: relative;
    cursor: pointer;
}

.b-sort__label {
    width: 130px;
    height: 32px;
    padding-left: 24px;
    line-height: 32px;
}

.b-sort__arrow {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 14px;
    height: 22px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAsCAMAAABFXPg2AAAAM1BMVEUAAAC/z8+/xs+/xs+/xc+/x8+/x8+/xc+/xs++xc6/xc+/xs+/xc+/xs+/xs+/xs+/xs+xpun0AAAAEHRSTlMAEPDgMGBAgMDQz6CfsHBQBAn0zAAAANRJREFUOMvFlFkSwyAMQ8EGkhBIff/TFqg7lMXjz76PLKNJJBZhBl6IwYg4IAInqTZSIVpBTtRIe9UT47fGLO7tLVblvusVV/tMhcuYq97zrD7tr+UrC1R4dsZueJyNObFf7M8WyzAt3tnV4xOL4XiHYQLHYr7xwu+LW5KC7cZ+M8EnG29he5KBIqMsY82NohrMH1Gi6QMDWQZlUpUl0RZU3w5sH7scm/G0FdNQxaxvZL0Geol6BSPHykKBU1qM9frrhwdXr1dxtb9aFa1y7ImE+dB8A+I6HfEgqO7kAAAAAElFTkSuQmCC) 0 0 no-repeat;
    background-size: 14px 22px;
}

.b-sort__drop-down {
    display: none;
    position: absolute;
    top: -16px;
    left: -6px;
    width: 160px;
    padding: 20px 10px 20px 30px;
    box-shadow: 0 5px 15px rgba(191, 198, 207, 0.2);
    border-radius: 10px;
    background-color: #ffffff;
    z-index: 10;
}

.b-sort__drop-down.opened {
    display: block;
}

.b-sort__item {
    margin-bottom: 10px;
    font-family: 'poppinslight', Arial, sans-serif;
    cursor: pointer;
}

.b-sort__item.active {
    color: #4f627a;
    font-family: 'poppinsmedium', Arial, sans-serif;
}

.b-sort__item:last-child {
    margin-bottom: 0;
}